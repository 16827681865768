/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { MetricService } from './metrics.service';

export const BUCKETS = [0, 2, 4, 8, 10, 15, 30, 60, 90];

const SLOW_MOUNT_THRESHOLD_SECONDS = 30;

/**
 * Sends a webui metric event per each MFE mounting time
 * It is important to check the lifecycle of single-spa events
 */
export default class MFEAppMonitor {
	/**
	 *  track when the router for the app is about to change
	 */
	private beforeAppChangeAt: number;

	constructor(private name: string, private metricService: MetricService) {
		this.metricService = metricService;
		this.name = name;
	}

	/**
	 * Bound to single-spa:before-app-change life-cycle event. It will be of use for both,
	 * AppLaunch (first full reload) and subsequent loading modes
	 */
	public setBeforeAppChangeTimestamp(timestamp: number = performance.now()) {
		this.beforeAppChangeAt = timestamp;
	}

	/**
	 * After a routing event https://single-spa.js.org/docs/api/#routing-event, send an observation about the MFE mount time to
	 * the observability system
	 * @param {number} timestamp The timestamp of when the routing event has finished
	 */
	public afterRoutingComplete({ timestamp }: { timestamp: number }) {
		const routingEventEllapsedTime = timestamp;
		const mfeMountTime = routingEventEllapsedTime - this.beforeAppChangeAt;
		const mfeMountTimeInSeconds = mfeMountTime / 1000;
		const mfeAppName = `MFE_${this.name}`;

		if (mfeMountTimeInSeconds > SLOW_MOUNT_THRESHOLD_SECONDS) {
			this.metricService.observeLog({
				app: mfeAppName,
				url: window.location.href,
				level: 'WARN',
				message: `Slow mounting MFE. ${mfeAppName} took ${mfeMountTimeInSeconds.toFixed(4)} seconds to mount`
			});
		}

		this.metricService
			.createHistogram({
				buckets: BUCKETS,
				help: 'The time it took for the MFE router to mount/unmount this MFE app.',
				name: 'ui_common_mfe_mount_seconds'
			})
			.observe(mfeMountTimeInSeconds, { app: mfeAppName });
	}
}
