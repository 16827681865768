/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { ForceAuthRequest } from './app-shell-force-auth.model';
import { LayerManagerService } from './app-shell-layer-manager.service';
import { AppShellStateProvider } from './app-shell-state.model';
import { AppShellUrlsProvider } from './app-shell-urls.model';
import { Branding } from './branding.model';
import { MFE_INFO_NAME, MfeInfo } from './mfe-info.model';
import { TLSData } from './tls.model';

/**
 * This AppShell model file is duplicated in various places so that
 * we don't have to create a shared repo for these models at this time.
 *
 * This file is the source of truth, and it is currently duplicated in
 * ui-common and the sp-renderer.
 */

/**
 * A string token that can be used to reference the AppShellService.
 */
export const APP_SHELL_SERVICE_NAME = 'app-shell-service';

/**
 * The default product name.
 */
export const DEFAULT_PRODUCT_NAME = 'SailPoint';

/**
 * Context data for the current logged in user
 */
export interface MfeUserContext {
	/**
	 * User Id - the alphanumeric uuid
	 */
	id: string;

	/**
	 * User's unique id
	 */
	uid: string;

	/**
	 * User's Display name
	 */
	displayName: string;

	/**
	 * User's alias
	 */
	alias: string;

	/**
	 * User's email
	 */
	email: string;

	/**
	 * The ams rights this user has. Ex: idn:admin, idn:helpdesk, etc.
	 * See https://github.com/sailpoint/authorization-model-service for more details.
	 * @deprecated - please use capabilities property.
	 * ToDo: remove property in PLTIN-5810, once PLTIN-5811 is complete
	 */
	amsRights: string[];

	/**
	 * The ams capabilities this user has. Ex: idn:admin, idn:helpdesk, etc.
	 * See https://github.com/sailpoint/authorization-model-service for more details.
	 */
	capabilities: string[];

	/**
	 * The ams ui rights this user has. Ex: idn:ui-identity-list-page:read, idn:ui-identity-list-page:write, etc.
	 * See https://github.com/sailpoint/authorization-model-service for more details.
	 */
	uiRights: string[];

	/**
	 * User's last login timestamp - in unix timestamp
	 */
	lastLoginTimestamp: number;

	/**
	 * Whether if the user is federated or not
	 */
	federated: boolean;
}

/**
 * Context data for the tenant
 */
export interface MfeTenantContext {
	/**
	 * Tenant id
	 */
	id: string;

	/**
	 * Org script name, ex: snoopy
	 */
	scriptName: string;

	/**
	 * Pod name, ex: megapod-useast1
	 */
	pod?: string;

	/**
	 * Org name
	 * Review: Wouldn't this be the same as script name, or is this meant to be the tenant URL?
	 */
	org: string;
}

/**
 * Authentication context data
 */
export interface MfeAuthContext {
	/**
	 * Logout url, ex: https://megapod-useast1.api.cloud.sailpoint.com/logout
	 */
	logoutUrl: string;

	/**
	 * Contains the base API urls to be used for certain products
	 */
	apiUrl: {
		/**
		 * IDN base API url, ex: https://megapod-useast1.api.cloud.sailpoint.com
		 */
		idn: string;
	};

	/**
	 * Url for the client to request a refreshed access token
	 */
	refreshUrl: string;

	/**
	 * Url for the client to request the forceAuthUrl
	 */
	forceAuthUrl: string;

	/**
	 * csrf token
	 */
	csrfToken: string;
}

/**
 * Enum to use for setting possible environments
 * Keep this synced with the model in src/common/model/url.model.ts
 */
export enum APIURLEnvironments {
	Prod = 'Prod',
	Dev = 'Dev'
}

/**
 * Context of the request used to load the AppShell
 */
export interface MfeRequestContext {
	/**
	 * Supported request locales
	 */
	locales: string[];

	/**
	 * Supported angular locale
	 */
	angularLocale: string;

	/**
	 * The language locale to use to load translations
	 */
	languagePackage: string;
	/**
	 * Validate development is a custom build
	 */
	devMode: boolean;

	/**
	 * Branding information
	 */
	brand: Branding;

	/**
	 * AppShell Base url ex: https://https://mfe-strad.identitysoon.com/ui/
	 */
	appShellBaseUrl: string;

	/**
	 * Set environment based on api url Prod/Dev
	 */
	mode: APIURLEnvironments;

	/**
	 * Set coiuntryCode got from login-info service
	 */
	countryCode: string;

	/**
	 * Set excludePaths got from appshell config
	 */
	excludePaths: Array<string>;

	/**
	 * Set possible languages that the selected app shell accepts
	 */
	appShellLanguages: Array<string>;
}

/**
 * Login context data
 */
export interface MfeLoginContext {
	/**
	 * Goto URL to communicate with Oathkeeper to complete
	 * the login flow when successful
	 */
	goto: string;

	/**
	 * Goto URL to where Oathkeeper redirects the user in case of fail
	 */
	gotoOnFail: string;

	/**
	 * URL to reset password, it's here so in case of custom branding
	 * we append the brand name to the URL
	 */
	resetUrl: string;

	/**
	 * Whether current org has network constrains or not
	 */
	hasNetworkConstraints: boolean;

	/**
	 * Whether to remember the current username or not
	 */
	rememberMe: boolean;
}

/**
 * The full context data provided by the AppShellService
 */
export interface MfeContextData {
	readonly userContext: Readonly<MfeUserContext>;
	readonly tenantContext: Readonly<MfeTenantContext>;
	readonly authContext: Readonly<MfeAuthContext>;
	readonly requestContext: Readonly<MfeRequestContext>;
	readonly loginContext?: Readonly<MfeLoginContext>;
}

/**
 * Context data for LaunchDarkly
 */
export interface LaunchDarklyContextData {
	clientSideId: string;
	featureFlagState: object;
	secureUserHash: string;
	fallback?: {
		featureFlagState: object;
		secureUserHash: string;
	};
}

/**
 * Context data for Pendo
 */
export interface PendoContextData {
	visitor: object;
	account: object;
	pendoApiKey: string;
}

/**
 * Credentials data needed by an MFE to access SP APIs.
 */
export interface MfeAuthCredentials {
	accessToken: string;
}

/**
 * Describes the feature flag value provider
 */
export interface FeatureFlagProvider {
	getFeatureFlagBoolean: (flagName: string) => boolean;
	getFeatureFlagNumeric: (flagName: string) => number;
}

/**
 * Get the navigation service
 */
export interface AppShellNavigationProvider {
	navigateToUrl: (url: string, newWindow?: boolean) => void;
}

/**
 * Get the force auth service
 */
export interface AppShellForceAuthProvider {
	forceAuthInit: (forceAuthRequest: ForceAuthRequest) => Promise<void>;
}

/**
 * Interface that defines the methods that must be
 * present on any service being used to communicate with the AppShell.
 */
export interface AppShellServiceMethods {
	getMfeContextV1: () => Promise<Readonly<MfeContextData>>;

	getUserContextV1: () => Promise<Readonly<MfeUserContext>>;

	getTenantContextV1: () => Promise<Readonly<MfeTenantContext>>;

	getAuthContextV1: () => Promise<Readonly<MfeAuthContext>>;

	getRequestContextV1: () => Promise<Readonly<MfeRequestContext>>;

	/**
	 * MFE's should not cache credentials. Instead they should request new
	 * credentials each time they are needed. If an MFE caches the credentials
	 * it runs into the possibility of the access token expiring.
	 */
	getAuthCredentialsV1: () => Promise<Readonly<MfeAuthCredentials>>;

	getAppShellStateProvider: () => Promise<AppShellStateProvider>;

	getFeatureFlagProvider: () => Promise<FeatureFlagProvider>;

	getLoginContextV1: () => Promise<Readonly<MfeLoginContext>>;

	getTLSData: () => Promise<Readonly<TLSData>>;

	getAppShellUrlsProvider: () => Promise<Readonly<AppShellUrlsProvider>>;

	getAppShellNavigationProvider: () => Promise<Readonly<AppShellNavigationProvider>>;

	getAppShellForceAuthProvider: () => Promise<AppShellForceAuthProvider>;

	getAppShellLayerManagerProvider: () => Promise<Readonly<LayerManagerService>>;
}

/**
 * The props that get passed down to each MFE.
 */
export interface MfeProps {
	/**
	 * The AppShellService instance for communication
	 * between MFEs and the AppShell. This provides the AppShell
	 * context data.
	 */
	[APP_SHELL_SERVICE_NAME]: AppShellServiceMethods;

	/**
	 * The Mfe Info object specific to an MFE.
	 * Provided by the AppShell
	 */
	[MFE_INFO_NAME]: MfeInfo;
}
