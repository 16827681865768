/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */
import { AppShellObservabilityService } from '../app-shell-observability.service';
import { FeatureFlagProvider } from '../app-shell.model';
import { MetricEvent } from '../metrics/metrics.model';
import { MfeInfo } from '../mfe-info.model';
import { AppShellFeatureFlagService } from './app-shell-feature-flag.service';

/**
 * Scoped feature flag service that logs when variations cannot be resolved.
 * We can use these metrics and logs to identify misconfigured feature flags.
 */
export class MfeFeatureFlagService implements FeatureFlagProvider {
	private static readonly evaluationMetric: MetricEvent = {
		type: 'COUNT',
		name: 'ui_app_shell_feature_flag_eval_counter',
		help: 'Tracks feature flag evaluations, per result and MFE app'
	};

	constructor(
		private mfeInfo: MfeInfo,
		private featureFlagService: AppShellFeatureFlagService,
		private observabilityService: AppShellObservabilityService
	) {}

	/**
	 * Get boolean feature flag
	 * @param flagName feature flag name
	 * @returns boolean variation value
	 */
	getFeatureFlagBoolean(flagName: string): boolean {
		const flagValue = this.featureFlagService.getFeatureFlagBoolean(flagName);
		if (typeof flagValue === 'boolean') {
			this.recordFeatureFlagHit();
		} else {
			this.recordFeatureFlagMiss(flagName);
			if (this.featureFlagService.fallbackLDClient) {
				return this.featureFlagService.fallbackLDClient.variation(flagName);
			}
		}
		return flagValue;
	}

	/**
	 * Get numeric feature flag
	 * @param flagName feature flag name
	 * @returns number variation value
	 */
	getFeatureFlagNumeric(flagName: string): number {
		const flagValue = this.featureFlagService.getFeatureFlagNumeric(flagName);
		if (typeof flagValue === 'number') {
			this.recordFeatureFlagHit();
		} else {
			this.recordFeatureFlagMiss(flagName);
			if (this.featureFlagService.fallbackLDClient) {
				return this.featureFlagService.fallbackLDClient.variation(flagName);
			}
		}
		return flagValue;
	}

	/**
	 * Send general metric for feature flag hits.
	 */
	private recordFeatureFlagHit(): void {
		this.observabilityService.observeMetric({
			...MfeFeatureFlagService.evaluationMetric,
			labels: {
				outcome: 'HIT',
				app: `MFE_${this.mfeInfo.name}`,
				flag: ''
			}
		});
	}

	/**
	 * Send log and metric to UMS for missed feature flags.
	 * @param flagName feature flag name
	 */
	private recordFeatureFlagMiss(flagName: string): void {
		this.observabilityService.observeLog({
			app: this.mfeInfo.name,
			url: window.location.href,
			level: 'WARN',
			message: `Feature flag variation for "${flagName}" cannot be resolved`
		});
		this.observabilityService.observeMetric({
			...MfeFeatureFlagService.evaluationMetric,
			labels: {
				outcome: 'MISS',
				app: `MFE_${this.mfeInfo.name}`,
				flag: flagName
			}
		});
	}
}
