/*
 * Copyright (C) 2025 SailPoint Technologies, Inc.  All rights reserved.
 */
import { Duration, ProfilingEntry, ProfilingEntryCreate, ProfilingEntryTargetType } from './profiler.model';

/**
 * @class Profiler
 * @description A comprehensive profiling utility that measures loading times for SingleSPA's Micro Frontend applications.
 * The profiler tracks three main metrics:
 * 1. Page load time - Time from URL entry to complete page load (only measured on first load)
 * 2. Application load time - Time for the application router to load files and mount all applications (only measured on first load)
 * 3. Individual MFE load times - Time for each application to initialize and render
 */
export class Profiler {
	private static _singleton = new Profiler();
	private _entries: ProfilingEntry[] = [];

	/**
	 * Get an instance of the profiler
	 * @example
	 * AppShellApplicationProfiler.getInstance();
	 * @returns {Profiler} The singleton instance
	 */
	static getInstance() {
		return Profiler._singleton;
	}

	/**
	 * Adds a new profiling entry
	 *
	 * @param {Object} entry - The profiling entry to add (without the type field, which is automatically set)
	 * @param {number} timestampOverride Override the timestamp relative to page load (as defined by the Performance API)
	 * @returns A void promise
	 */
	public async addProfileEntry(entry: ProfilingEntryCreate, timestampOverride?: number): Promise<void> {
		const timestamp = timestampOverride !== undefined ? timestampOverride : performance.now();
		this._entries.push({
			...entry,
			timestamp
		});
	}

	/**
	 * Gets the Time To Interactive durations (ready) for all tracked applications.
	 * This duration is the comparison between the timestamp (performance API) of when the files started loading
	 * to the timestamp of when we can interact with the MFE
	 * @returns A promise that resolves to an array of durations
	 */
	public async getTimeToReadyDurations(): Promise<Duration[]> {
		return this.getDurations({ target: 'ready' });
	}

	/**
	 * Gets the Time To Mount durations for all tracked applications.
	 * This duration is the comparison between the timestamp (performance API) of when the files started loading
	 * to the timestamp of when Single Spa initializes the MFE execution
	 * @returns A promise that resolves to an array of durations
	 */
	public async getTimeToInitializingDurations(): Promise<Duration[]> {
		return this.getDurations({ target: 'initializing' });
	}

	/**
	 * Marks the start of a routing operation in the profiler.
	 *
	 * @returns A promise that resolves when the routing start has been recorded
	 */
	public async routingStart() {
		this._entries = [];
	}

	private async getDurations({ target }: { target: ProfilingEntryTargetType }): Promise<Duration[]> {
		const mountEntries = this._entries.filter(entry => entry.kind === target);

		const durations = mountEntries.reduce((acc: Duration[], { name, timestamp: ttiStart }) => {
			const loadEntry = this._entries.find(entry => entry.name === name && entry.kind === 'load');

			if (loadEntry) {
				return [...acc, { name, duration: ttiStart - loadEntry.timestamp }];
			}
			return acc;
		}, [] as Duration[]);

		return durations;
	}
}
