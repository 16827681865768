/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */
import { MetricEvent } from './metrics/metrics.model';

/**
 * UMS endpoint to record metrics and logging events
 */
export const BEACON_SERVICE_URL = 'ums/webui-beacon';

/**
 * The delay in milliseconds to emit the batch of metrics and logs to the UMS endpoint
 */
export const SEND_METRICS_LOGS_DELAY_INTERVAL = 60000;

/**
 * Max count of the allowed metrics to send to the API in one batch
 */
export const MAX_METRIC_COUNT = 100;

export const unloadEventTypes = ['beforeunload', 'unload'];

export interface LogEvent {
	/**
	 * The name of the UI module or MFE
	 */
	app: string;

	/**
	 * The current URL when the log message was generated
	 */
	url: string;

	/**
	 * The logging level of the message
	 */
	level: 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';

	/**
	 * The message to be logged
	 */
	message: string;
}

export interface AppShellObservabilityProvider {
	observeMetric(metric: MetricEvent);
	observeLog(log: LogEvent);
}
