/*
 * Copyright (C) 2021 SailPoint Technologies, Inc. All rights reserved.
 */
import { FeatureFlags } from '../../src/feature-flags';
import { Topic } from './app-shell-state.model';
import {
	APP_SHELL_SERVICE_NAME,
	LaunchDarklyContextData,
	MfeContextData,
	MfeProps,
	PendoContextData
} from './app-shell.model';
import { AppShellService } from './app-shell.service';
import { MfeAccessToken } from './auth-credential.model';
import { AuthCredentialsService } from './auth-credential.service';
import { buildAppRoutesHtml, buildRouteLoaders } from './engine-functions';
import { AppShellFeatureFlagService } from './feature-flag/app-shell-feature-flag.service';
import ImportMapOverridesService from './import-map-overrides/import-map-overrides.service';
import { LegacyBrandingService } from './legacy-branding/legacy-branding.service';
import { LoadTimeMetricsService } from './metrics/load-time-metrics-service';
import { LoadTimeMetricsService as LoadTimeMetricsServiceOld } from './metrics/load-time-metrics-service-old';
import { MFE_INFO_NAME, MfeInfo } from './mfe-info.model';
import { MfeMetaPosition, RegisterConfig } from './mfe-register.model';
import { PageVisibilityService } from './page-visibility.service';
import { bestRouteMatches, firstMatchedRoute } from './path-functions';
import { PendoService } from './pendo-service';
import './polyfills';
import { TLSData } from './tls.model';
import { TranslationService } from './translation.service';
import { createNotFoundPage } from './views/not-found-page/not-found-page';
import { createSessionExpiredPage } from './views/session-expired-page/session-expired-page';
import {
	ActivityFn,
	RegisterApplicationConfig,
	getMountedApps,
	registerApplication,
	start,
	triggerAppChange
} from 'single-spa';
import { WithLoadFunction, constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import 'systemjs';

const applications: (RegisterApplicationConfig<MfeProps> & WithLoadFunction)[] = [];

/* global System */

// we want to get this start time as soon as possible.
const startTime = performance.now();
const pageVisibilityService = new PageVisibilityService();

/**
 * Unescape JSON content embedded inside <script> blocks on the page. This is the oposite encoding
 * from the template-data.service's stringifyJSONEmbededInHTML() method.
 *
 * TODO: PLTUI-9835, When PLTUI_ESCAPE_JSON_TEMPLATE_DATA flag, please remove the escaped
 * argument for this function and look up for the escaped attribute.
 */
function unescape(escape: string, input: string) {
	if (escape !== 'false') {
		return input.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
	} else {
		return input;
	}
}

// Get the credentials used to authenticate API calls by MFEs.
const credentialElement = document.getElementById('mfe-credential-json');
const mfeAccessToken: MfeAccessToken = credentialElement
	? (JSON.parse(unescape(credentialElement.attributes['escaped'], credentialElement.textContent)) as MfeAccessToken)
	: null;

credentialElement.remove();

// Get the context data that applies to all MFEs.
const contextElement = document.getElementById('mfe-context-json');
const mfeContextData: MfeContextData = contextElement
	? (JSON.parse(unescape(contextElement.attributes['escaped'], contextElement.textContent)) as MfeContextData)
	: null;

contextElement.remove();

// Create the credential service. This will poll for a refreshed access token.
// This should be there only when user is authenticated.
const authCredentialsService = mfeAccessToken
	? new AuthCredentialsService(
			mfeAccessToken.accessToken,
			mfeContextData.authContext.csrfToken,
			mfeAccessToken.refreshInterval,
			mfeContextData.authContext.refreshUrl,
			mfeContextData.authContext.logoutUrl,
			pageVisibilityService
	  )
	: null; // in case of unauthenticated app shells

// Get Launchdarkly client side id
const ldClientSideDataElement = document.getElementById('ld-context-json');
const ldClientSideData: LaunchDarklyContextData = ldClientSideDataElement
	? (JSON.parse(
			unescape(ldClientSideDataElement.attributes['escaped'], ldClientSideDataElement.textContent)
	  ) as LaunchDarklyContextData)
	: null;
ldClientSideDataElement.remove();

// Get the TLS
const tlsElement = document.getElementById('tls-json');
const tlsData: TLSData = tlsElement
	? (JSON.parse(unescape(tlsElement.attributes['escaped'], tlsElement.textContent)) as TLSData)
	: null;
tlsElement.remove();

const urlDataElement = document.getElementById('urls-json');
const urlData = urlDataElement
	? JSON.parse(unescape(urlDataElement.attributes['escaped'], urlDataElement.textContent))
	: null;
urlDataElement.remove();

// Create the app shell service. This will be provided to each MFE. Add the credential service as a
// dependency, to ensure that the app shell service always has a valid access token.
const appShellService = new AppShellService({
	authCredentialsService,
	tlsData,
	urlData,
	data: mfeContextData,
	ldContext: ldClientSideData
});

const apiUrl = mfeContextData.authContext.apiUrl.idn;

// we have to use the synchronous data and not the appshell wrapper because if
// we used the async API, we would create a race condition when overriding the imports
// thus making the plugin useless. This import should happen before we create the singleSPA layout
// and register applications
const importMapOverridesService = new ImportMapOverridesService(
	mfeContextData?.requestContext,
	ldClientSideData?.featureFlagState
);
if (importMapOverridesService.isEnabled()) {
	// dynamically import the node package
	import('import-map-overrides');
	document.body.appendChild(importMapOverridesService.buildUITag());
}

// This routing hook is triggered to fire after all single-spa applications have been unmounted,
// but before any new applications have been mounted
window.addEventListener('single-spa:before-mount-routing-event', async (evt: CustomEvent) => {
	const { appsByNewStatus } = evt.detail;

	// Reset navbar when an unmounting process event happens
	if (appsByNewStatus.NOT_MOUNTED.length > 0) {
		(await appShellService.getAppShellStateProvider()).emit({
			topic: Topic.ChangeIsNavbarVisible,
			payload: { isNavbarVisible: true }
		});
	}
});

// Set a listener for the custom event 'locationchange'. SPRenderer will emit and each MFE will subscribe to location changes.
appShellService.getAppShellStateProvider().then(async appShellStateProvider => {
	await appShellStateProvider.set({ currentUrl: window.location.href });

	window.addEventListener('single-spa:routing-event', async (ev: CustomEvent) => {
		const { newUrl } = ev.detail;
		await appShellStateProvider.emit({ topic: Topic.ChangeCurrentUrl, payload: { currentUrl: newUrl } });
	});
});

// Get the configuration data for each MFE to register
const configsElement = document.getElementById('mfe-configs-json');
const mfeConfigs: RegisterConfig[] = contextElement
	? (JSON.parse(unescape(configsElement.attributes['escaped'], configsElement.textContent)) as RegisterConfig[])
	: [];

// Initialize legacy branding stylesheet only for opted-in MFEs
const legacyBranding = new LegacyBrandingService(mfeContextData.requestContext, mfeConfigs);
legacyBranding.appendLegacyBrandingStylesheet();

// This data is set in the app-shell.config and rendered through the app-shell.controller and the template-data.service
const appShellContextElement = document.getElementById('app-shell-context');
const appShellContextData = JSON.parse(
	unescape(appShellContextElement.attributes['escaped'], appShellContextElement.textContent)
);
const { metaMFEs } = appShellContextData;
const contentMFEs = mfeConfigs.filter(
	mfe => !metaMFEs?.some(meta => meta.position !== MfeMetaPosition.Content && meta.mfeName === mfe.specifier)
);
appShellContextElement.remove();

// Get the pendo information to run the script and initialize it, this information is organized in
// template-data.service
const pendoDataElement = document.getElementById('pendo-context-json');
const pendoData: PendoContextData = pendoDataElement
	? (JSON.parse(unescape(pendoDataElement.attributes['escaped'], pendoDataElement.textContent)) as PendoContextData)
	: null;
pendoDataElement.remove();

// Service to run pendo script and then initialize it with the current information
// eslint-disable-next-line no-new
new PendoService(pendoData, document);

// Create static MFEs
const translationService = new TranslationService();
const sessionExpiredPage = createSessionExpiredPage(translationService);
const notFoundPage = createNotFoundPage(translationService);
const staticMFEs = [sessionExpiredPage, notFoundPage];
for (const pack of Array.from(new Set(staticMFEs.map(mfe => mfe.languagePackage)))) {
	translationService.load(pack, mfeContextData.requestContext.languagePackage);
}

// Construct the layout of MFEs
const appRoutesHtml = buildAppRoutesHtml(mfeConfigs, { metaMFEs, staticMFEs });
document.getElementById('single-spa-layout').innerHTML = appRoutesHtml;

async function initialize() {
	const featureFlagService: AppShellFeatureFlagService = await appShellService.getFeatureFlagProvider();

	const loadTimeMetricsService = new LoadTimeMetricsService({
		appShellService,
		apiUrl
	});

	const realTimeUserMonitoring = new LoadTimeMetricsServiceOld({
		appShellService,
		startTime,
		apiUrl
	});

	// For each of the MFE configurations, register it with single-spa.n
	for (const config of mfeConfigs) {
		// Activity FN to pass to activeWhen
		const bestRouteMatch: ActivityFn = location => {
			if (authCredentialsService?.isSessionExpired) {
				return false;
			}
			const matchRoutePrefix =
				featureFlagService?.getFeatureFlagBoolean(FeatureFlags.PLTUI9727_MATCH_ROUTE_PREFIX) ?? false;
			return bestRouteMatches(config, mfeConfigs, location, matchRoutePrefix);
		};

		// Create the registration configuration object, assign MFE context data object as "customProps"
		// https://single-spa.js.org/docs/configuration#using-configuration-object
		applications.push({
			name: config.name,
			app: () => {
				// SingleSPA will call this function to load all needed files for the MFE application.
				if (featureFlagService.getFeatureFlagBoolean(FeatureFlags.PLTUI10159_MFE_MOUNT_TIMES_NEW_PROFILING)) {
					loadTimeMetricsService.addProfileEntry({
						name: config.name,
						kind: 'load'
					});
				}

				return System.import(config.specifier);
			},

			activeWhen: bestRouteMatch,
			customProps: () => {
				// Get the config route that was matched to load this MFE.
				const currentRoute = firstMatchedRoute(config, window.location);

				// Define the information specific to each MFE.
				const mfeInfo: MfeInfo = {
					name: config.name,
					route: currentRoute,
					url: config.url
				};

				const mfeCustomProps: MfeProps = {
					[APP_SHELL_SERVICE_NAME]: appShellService.getProxiedAppshell(mfeInfo),
					[MFE_INFO_NAME]: mfeInfo
				};

				return mfeCustomProps;
			}
		});
	}

	// Apply layout and register each of the applications
	const layoutRoutes = constructRoutes(document.querySelector('#single-spa-layout').innerHTML, {
		props: {},
		loaders: buildRouteLoaders()
	});
	// Construct layout applications to replace the loading function
	//  `app` with a wrapped version that supports a loading state
	const layoutApplications = constructApplications({
		routes: layoutRoutes,
		loadApp: app => {
			const { app: loadFn } = applications.find(({ name }) => name === app.name) ?? {};
			if (!loadFn) {
				return Promise.reject(Error('Tried to load app that does not have a load function'));
			}
			return loadFn(app);
		}
	})
		.map(layoutApp => {
			const mfeApp = applications.find(({ name }) => name === layoutApp.name);
			return { ...mfeApp, app: layoutApp.app };
		})
		.filter(app => app.name);

	constructLayoutEngine({ routes: layoutRoutes, applications: layoutApplications });

	layoutApplications.forEach(registerApplication);

	// Register static MFEs
	const staticPageState = {
		notFoundActive: false,
		topMFEActive: false
	};

	// Register the 'Session Expired' page.
	registerApplication({
		name: sessionExpiredPage.pageName,
		app: sessionExpiredPage,
		activeWhen: () => authCredentialsService?.isSessionExpired,
		customProps: {
			logoutUrl: mfeContextData.authContext.logoutUrl,
			languagePackage: mfeContextData.requestContext.languagePackage
		}
	});

	// this custom event is the first in order during a re-route or initial load
	if (featureFlagService.getFeatureFlagBoolean(FeatureFlags.PLTUI8691_MFE_404_PAGE)) {
		// Register the 'Not Found' fallback page
		registerApplication({
			name: notFoundPage.pageName,
			app: notFoundPage,
			activeWhen: () => staticPageState.notFoundActive,
			customProps: {
				languagePackage: mfeContextData.requestContext.languagePackage,
				get showHeader() {
					return !staticPageState.topMFEActive;
				}
			}
		});

		// react to route change
		window.addEventListener('single-spa:routing-event', () => {
			const mountedAppNames = getMountedApps();

			// check if we have a navbar or other top MFE, to hide the static page header
			const topMetaMFEs = mountedAppNames.filter(name =>
				metaMFEs?.some(meta => meta.position === MfeMetaPosition.Top && meta.mfeName === name)
			);
			staticPageState.topMFEActive = topMetaMFEs.length > 0;

			// show the not found page if there are no content or static MFEs mounted
			const mountedContentMFEs = mountedAppNames.filter(
				name => contentMFEs.some(mfe => mfe.specifier === name) || staticMFEs.some(mfe => mfe.pageName === name)
			);
			const notFound = mountedContentMFEs.length === 0 && !authCredentialsService?.isSessionExpired;
			if (notFound && notFound !== staticPageState.notFoundActive) {
				triggerAppChange(); // render the not found page
			}
			staticPageState.notFoundActive = notFound;
		});
	}

	window.addEventListener('single-spa:before-app-change', async (evt: CustomEvent) => {
		if (featureFlagService.getFeatureFlagBoolean(FeatureFlags.PLTUI10159_MFE_MOUNT_TIMES_NEW_PROFILING)) {
			loadTimeMetricsService.startProfiling();
		} else {
			const { originalEvent, appsByNewStatus } = evt.detail;
			const mountedApps = appsByNewStatus.MOUNTED;

			// The absence of 'originalEvent' tells this is an initial load
			if (!originalEvent) {
				realTimeUserMonitoring.trackApplicationStart(startTime);
			} else {
				// clean app monitoring between routing events (not initial load)
				realTimeUserMonitoring.clearApps();
			}
			// always monitor MFEs
			mountedApps.forEach(appName => {
				realTimeUserMonitoring.monitorApp(appName);
				realTimeUserMonitoring.observeAppChangeStart(appName);
			});
		}
	});

	window.addEventListener('single-spa:app-change', async (evt: CustomEvent) => {
		if (featureFlagService.getFeatureFlagBoolean(FeatureFlags.PLTUI10159_MFE_MOUNT_TIMES_NEW_PROFILING)) {
			// using this event listener is more effective than using single-spa:routing-event:
			// 1. It triggers once, making it easier to control entries
			// 2. It happens right after the app can interact, improving time measurement
			await loadTimeMetricsService.finishProfiling();

			loadTimeMetricsService.observeDurations();
		} else {
			// old code
			const mountedApps = evt.detail.appsByNewStatus.MOUNTED;
			const { originalEvent } = evt.detail;

			mountedApps.forEach(appName => {
				// the before-app-change event has already set the intialLoad status on
				// which the object will determine what to calculate after the routing is complete
				realTimeUserMonitoring.routingComplete(appName, performance.now());
			});

			if (!originalEvent && mountedApps?.length > 0) {
				realTimeUserMonitoring.trackApplicationEnd(performance.now());
			}
		}
	});
	/**
	 * Start single-spa listening to routes.
	 * We need to set urlRerouteOnly as false since Angular based MFEs does not work well with this property enabled.
	 * For more information, please look for 'urlRerouteOnly' in https://single-spa.js.org/docs/api/
	 */
	start({
		urlRerouteOnly: false
	});
}

initialize();
