/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { AppShellService } from '../app-shell.service';
import { Profiler } from '../profiling/profiler';
import { Duration, ProfilingEntryCreate } from '../profiling/profiler.model';
import { MetricService } from './metrics.service';

const BUCKETS = [0, 2, 4, 8, 10, 15, 30, 60, 90];
const SLOW_TIME_TO_READY_THRESHOLD_SECONDS = 30;

interface InitArgs {
	apiUrl: string;
	debug?: boolean;
	appShellService: AppShellService;
}

/**
 * represent the profiled durations that are not MFE applications.
 * PLEASE KEEP UPDATED WHEN ADDING NEW VALUES TO THE PROFILED NAMES
 */
const MFE_DURATIONS_EXCLUDE_LIST = ['appshell', 'page'];
export class LoadTimeMetricsService {
	private _firstLoad = true;
	private metricService: MetricService;
	private profiler: Profiler;

	/**
	 *
	 * @param nameContext - The identifier of the microfront End from which we are going to store metrics
	 * @param startTime - The initial loading time to capture the metrics
	 * @param appShellService - The instance for appShell Service
	 */
	constructor({ apiUrl, appShellService }: InitArgs) {
		this.metricService = new MetricService(apiUrl, appShellService);
		this.profiler = Profiler.getInstance();
	}

	/**
	 * Adds 'load' profiling entries for page and app. Only
	 * added on first load.
	 */
	public async startProfiling() {
		// clear profiler entries
		await this.profiler.routingStart();

		if (this._firstLoad) {
			this.profiler.addProfileEntry({
				name: 'appshell',
				kind: 'load'
			});

			this.profiler.addProfileEntry(
				{
					name: 'page',
					kind: 'load'
				},
				// it's important to override here the timestamp to 0, to start measuring page load time from navigation start.
				0
			);
		}
	}

	/**
	 * Adds 'ready' profiling entries for page and app. Only added on first load.
	 * Turns an internal flag on to stop tracking after first load
	 */
	public async finishProfiling() {
		if (this._firstLoad) {
			await this.profiler.addProfileEntry({
				name: 'appshell',
				kind: 'ready'
			});
			await this.profiler.addProfileEntry({
				name: 'page',
				kind: 'ready'
			});
		}
		this._firstLoad = false;
	}

	public async addProfileEntry(entry: ProfilingEntryCreate) {
		this.profiler.addProfileEntry(entry);
	}

	public async observeDurations() {
		const durations = await this.profiler.getTimeToReadyDurations();
		const applicationDuration = durations.find(({ name }) => name === 'appshell');
		if (applicationDuration) {
			this.metricService
				.createHistogram({
					name: `ui_common_app_load_seconds`,
					help: 'The time it takes from the moment the script starts executing to the end of the first user interactivity for the app shell.',
					buckets: BUCKETS
				})
				.observe(applicationDuration.duration / 1000, {
					app: `MFE_appshell`
				});
		}

		const pageDuration = durations.find(({ name }) => name === 'page');
		if (pageDuration) {
			this.metricService
				.createHistogram({
					name: `ui_common_page_load_seconds`,
					help: 'The time it takes from navigation start to the first user interactivity for the app shell.',
					buckets: BUCKETS
				})
				.observe(pageDuration.duration / 1000, { app: `MFE_appshell` });
		}

		const mfeTimeToReadyDurations = durations.filter(
			duration => !MFE_DURATIONS_EXCLUDE_LIST.includes(duration.name)
		);
		mfeTimeToReadyDurations.forEach(duration => this.sendMfeTimeToInteractiveMetric(duration));

		// time to initializing metric is only available for MFEs
		const mfeTimeToInitializingDurations = await this.profiler.getTimeToInitializingDurations();
		mfeTimeToInitializingDurations.forEach(({ duration, name }) => {
			this.metricService
				.createHistogram({
					buckets: BUCKETS,
					help: 'The time it takes during a routing change from when the MFE files start loading to when the MFE started initializing.',
					name: 'ui_common_mfe_initializing_seconds'
				})
				.observe(duration / 1000, { app: `MFE_${name}` });
		});
	}

	private sendMfeTimeToInteractiveMetric({ duration, name: appName }: Duration) {
		const mfeMountTimeInSeconds = duration / 1000;
		const mfeAppName = `MFE_${appName}`;

		if (mfeMountTimeInSeconds > SLOW_TIME_TO_READY_THRESHOLD_SECONDS) {
			this.metricService.observeLog({
				app: mfeAppName,
				url: window.location.href,
				level: 'WARN',
				message: `Slow mounting MFE. ${mfeAppName} took ${mfeMountTimeInSeconds.toFixed(4)} seconds to mount`
			});
		}

		this.metricService
			.createHistogram({
				buckets: BUCKETS,
				help: 'The time it takes during a routing change from when the MFE files start loading to when the MFE is ready for interactivity.',
				name: 'ui_common_mfe_mount_seconds'
			})
			.observe(mfeMountTimeInSeconds, { app: mfeAppName });
	}
}
