/*
 * Copyright (C) 2024 SailPoint Technologies, Inc. All rights reserved.
 */
import {
	AppShellObservabilityProvider,
	BEACON_SERVICE_URL,
	LogEvent,
	MAX_METRIC_COUNT,
	SEND_METRICS_LOGS_DELAY_INTERVAL,
	unloadEventTypes
} from './app-shell-observability.model';
import { AppShellService } from './app-shell.service';
import { MetricEvent } from './metrics/metrics.model';

export class AppShellObservabilityService implements AppShellObservabilityProvider {
	private bufferedMetrics: MetricEvent[] = [];
	private bufferedLogs: LogEvent[] = [];
	private beaconApiUrl: string;
	private sendBufferedMetricsAnsLogsInterval;

	constructor(private appShellService: AppShellService) {
		this.registerShutdownListener();
		this.setBeaconAPIUrl();
		this.setBufferInterval();
	}

	/**
	 * Receives a metric observation. This would take the metric event defined by armada.
	 */
	public observeMetric(metric: MetricEvent) {
		this.bufferedMetrics.push(metric);
	}

	/**
	 * Receives a log observation.
	 */
	public observeLog(log: LogEvent) {
		this.bufferedLogs.push(log);
	}

	/**
	 * Returns the current access token
	 */
	private async getAccessToken() {
		const credentials = await this.appShellService.getAuthCredentialsV1();
		return credentials ? credentials.accessToken : null;
	}

	/**
	 * Simple utility to ensure that the url ends with a trailing slash.
	 *
	 * @param {string} url - The original url
	 * @returns {string} The url with a trailing slash.
	 */
	private ensureTrailingSlash(url: string): string {
		if (!url || url.endsWith('/')) {
			return url;
		}

		return `${url}/`;
	}

	/**
	 * Method to set the API url endpoint
	 */
	private async setBeaconAPIUrl() {
		const authContext = await this.appShellService.getAuthContextV1();
		const baseUrl = this.ensureTrailingSlash(authContext.apiUrl?.idn);
		this.beaconApiUrl = baseUrl + BEACON_SERVICE_URL;
	}

	/**
	 * Sets the interval to send the buffered metrics and logs events
	 */
	private setBufferInterval() {
		this.sendBufferedMetricsAnsLogsInterval = setInterval(() => {
			this.sendMetricsAndLogsToUMS();
		}, SEND_METRICS_LOGS_DELAY_INTERVAL);
	}

	/**
	 * Method to send the metrics and logs to the UMS endpoint every 1 minute using Beacon API
	 */
	private async sendMetricsAndLogsToUMS() {
		if (this.bufferedMetrics.length === 0 && this.bufferedLogs.length === 0) {
			// No metrics to send, return immediately.
			return;
		}

		// Send the first batch of 100 events
		const requestObj = {
			metrics: this.bufferedMetrics.splice(0, MAX_METRIC_COUNT),
			logs: this.bufferedLogs.splice(0, MAX_METRIC_COUNT)
		};
		const accessToken = await this.getAccessToken();
		if (accessToken) {
			requestObj['token'] = accessToken;
		}
		navigator.sendBeacon(this.beaconApiUrl, JSON.stringify(requestObj));

		if (this.bufferedMetrics.length > 0 || this.bufferedLogs.length > 0) {
			// If there are more events remaining, then send them immediately.
			this.sendMetricsAndLogsToUMS();
		}
	}

	/**
	 * Callback function for page unload event
	 */
	private unloadFn() {
		this.sendMetricsAndLogsToUMS();
	}

	/**
	 * Registers an event listener to listen to browser`s unload and beforeunload event
	 */
	private registerShutdownListener() {
		unloadEventTypes.forEach(eventType => window.addEventListener(eventType, this.unloadFn.bind(this)));
	}
}
