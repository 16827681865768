/*
 * Copyright (C) 2022 SailPoint Technologies, Inc.  All rights reserved.
 */
import { RegisterConfig } from './mfe-register.model';

/**
 * Utility functions for building URL paths
 */

/**
 * Utility function to trim leading an trailing slashes. Used to compose valid paths delimited by single slashes.
 * @param str string
 * @returns string
 */
export function trimSlashes(str: string) {
	if (str != null && str.length) {
		while (str.length && str[str.length - 1] === '/') {
			str = str.slice(0, str.length - 1);
		}
		while (str.length && str[0] === '/') {
			str = str.slice(1, str.length);
		}
	}
	return str || '';
}

/**
 * Compose a valid path delimited by single slashes.
 * @param p string - 1 to n strings to combine
 * @returns string - single string composed of arguments joined by slashes
 */
export function joinPath(...p: string[]) {
	return trimSlashes(
		p
			.map(trimSlashes)
			.filter(x => x)
			.join('/')
	);
}

/**
 * Clean and decode string from '+'s because the javascript library doesn't convert them to spaces
 * @param {string} str string to decode
 * @returns string
 */
export function urlDecode(str: string) {
	return decodeURIComponent(str).replace(/\+/g, ' ');
}

/**
 * Validates if one path contains a specific route
 * @param {string} path value used as based path, in order to search for the given path
 * @param {string} searchPath we going to search this value within the base path
 * @returns boolean
 */
export function includePath(path: string, searchPath: string) {
	return encodeURIComponent(urlDecode(path)).indexOf(encodeURIComponent(urlDecode(searchPath))) === 0;
}

/**
 * Get the appShell path
 * @returns The path for the appShell
 */
export const appShellPath = () => document.getElementById('app-shell-path')?.textContent?.trim() || '/';

/**
 *  Function to find the first configured route for an MFE. Loops the configured routes and
 *  compares current location. Returns the first one matched.
 * @param config - Registered Config for MFE
 * @param location - Current Location
 * @returns
 */
export const firstMatchedRoute: (config: RegisterConfig, location: Location) => string | null = (config, location) => {
	for (const route of config.routes) {
		const fullMatchPath = route === '*' ? `/${trimSlashes(appShellPath())}` : `/${joinPath(appShellPath(), route)}`;

		if (includePath(location.pathname, fullMatchPath)) {
			return fullMatchPath;
		}
	}
	return null;
};

/**
 * Get length of matching route segments, but allow paths to deviate.
 * Remove when PLTUI9727_MATCH_ROUTE_PREFIX is cleaned up.
 * @deprecated Use getMatchingLength
 * @param appRoute - Current route of the application
 * @param configRoute - MFE config route to process and compare best matching
 * @returns Route matching score (how many sequential segments match)
 */
function getMatchingLengthNonStrict(appRoute: string, configRoute: string) {
	// Break the app route and MFE config route into segments for comparison
	const appRouteSegments = appRoute.split('/').filter(Boolean);
	const configRouteSegments = configRoute.split('/').filter(Boolean);

	let matchLength = 0;

	// Iterate over each segment track until they deviate.  Record best matching score
	for (let i = 0; i < Math.min(configRouteSegments.length, appRouteSegments.length); i++) {
		const appRouteSegment = appRouteSegments[i];
		const configRouteSegment = configRouteSegments[i];

		// Take special cases into consideration, such as wildcard and dynamic segments
		if (configRouteSegment.startsWith(':') || configRouteSegment === appRouteSegment) {
			matchLength++;
		} else break;
	}

	// Return match length.
	return matchLength === configRouteSegments.length ? matchLength + 1 : matchLength;
}

/**
 * Get length of matching MFE route segments for the current app path.
 * The current app path must be prefixed by the route path in order to match.
 * @param appRoute - Current route of the application
 * @param configRoute - MFE config route to process and compare best matching
 * @returns Route matching score (how many sequential segments match)
 */
function getMatchingLength(appRoute: string, configRoute: string) {
	// Break the app route and MFE config route into segments for comparison
	const appRouteSegments = appRoute.split('/').filter(Boolean);
	const configRouteSegments = configRoute.split('/').filter(Boolean);
	if (configRouteSegments.length === 0) {
		return 0;
	}
	const allMatching = configRouteSegments.every((configSegment, index) => {
		const appRouteSegment = appRouteSegments[index];
		// Pass dynamic segments starting with ":"
		return appRouteSegment === configSegment || configSegment.startsWith(':');
	});
	return allMatching ? configRouteSegments.length : 0;
}

/**
 * Function to find the best configured route for an MFE. Loops the configured routes and
 * compares current location.
 * @param currentMfe - Config for MFE we want to test
 * @param mfeConfigs - All MFE configs
 * @param location - Current Location
 * @param matchRoutePrefix - Feature flag toggle for strict route prefix matching
 * @returns true if currentMfe has the best matching route
 */
export function bestRouteMatches(
	currentMfe: RegisterConfig,
	mfeConfigs: RegisterConfig[],
	location: Location,
	matchRoutePrefix = false
): boolean {
	// TODO: Need to re-evaluate MFE's using wildcard routes
	if (currentMfe.routes[0] === '*') return true;

	// Get the current app route
	const appRoute = location.pathname;

	let bestMatchMFE: RegisterConfig | null = null;
	let bestMatchLength = matchRoutePrefix ? 0 : -1;

	// Iterate over every route for every MFE to find which one best matches the current route
	for (const config of mfeConfigs) {
		for (const route of config.routes) {
			const mfeRoute = joinPath(appShellPath(), route);
			const matchFn = matchRoutePrefix ? getMatchingLength : getMatchingLengthNonStrict;
			const matchLength = matchFn(trimSlashes(appRoute), trimSlashes(mfeRoute));

			if (matchLength > bestMatchLength) {
				bestMatchLength = matchLength;
				bestMatchMFE = config;
			}
		}
	}

	// If MFE config best matches current route, then return true
	return bestMatchMFE?.name === currentMfe.name;
}
