/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { LogEvent } from '../app-shell-observability.model';
import { AppShellService } from '../app-shell.service';
import { Histogram, MetricEvent, MetricOptions } from './metrics.model';

export class MetricService {
	private static readonly WEBUI_METRIC_URL = 'ums/webui-metric';
	private baseUrl: string;

	constructor(private apiUrl: string, private appShellService: AppShellService) {
		this.initialize();
	}

	/**
	 * Creates histogram metric instance for recording metrics
	 * @param options - The list of options to be used when creating a histogram metric
	 * @returns Histogram metric instance
	 */
	public createHistogram(options: MetricOptions): Histogram {
		return new Histogram(
			metric => this.sendMetricEvent(metric),
			options.name,
			options.help,
			options.labels,
			options.buckets
		);
	}

	/*
	* Send a metric event to the API.
	@param {MetricEvent}
	*/
	public async sendMetricEvent(metric: MetricEvent): Promise<void> {
		(await this.appShellService.getAppShellObservabilityProvider()).observeMetric(metric);
	}

	/**
	 *
	 */
	public async observeLog(logEvent: LogEvent) {
		(await this.appShellService.getAppShellObservabilityProvider()).observeLog(logEvent);
	}

	private async initialize() {
		const url = this.apiUrl;
		this.baseUrl = this.ensureTrailingSlash(url);
		if (this.baseUrl === undefined) {
			throw new Error('Failed to set API URL.');
		}
	}

	/**
	 * Simple utility to ensure that the URL ends with a trailing slash.
	 *
	 * @param {string} url - The original URL
	 * @returns {string} The URL with a trailing slash.
	 */
	private ensureTrailingSlash(url: string) {
		if (!url || url.endsWith('/')) {
			return url;
		}

		return `${url}/`;
	}
}
export default MetricService;
